import * as React from 'react';
import { SVGProps } from 'react';

const SvgSearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m17.943 17.058-3.912-3.91a6.883 6.883 0 1 0-.883.883l3.91 3.911a.626.626 0 0 0 .885-.884ZM3.126 8.75a5.625 5.625 0 1 1 5.625 5.625A5.631 5.631 0 0 1 3.126 8.75Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgSearchIcon;
