import { Theme, ThemeConfig, ThemeDesignConfig } from '../contracts';

import { images } from './config-images';
import { paymentImages } from './config-pm-images';

export const themeConfig: ThemeConfig = {
  defaultTitle: 'TopRate',
  name: 'TopRate',

  // images2
  errorIcon: images.errorIcon.path,
  successIcon: images.successIcon.path,
  infoIcon: images.infoIcon.path,
  crossArrowsIcon: `url(${images.crossArrowsIcon.path}) no-repeat 50% / contain`,

  // fonts
  fontFamily: 'Nunito Sans',
  fontFamilyCyrillic: 'Nunito Sans Cyrillic',
  mainFont: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',

  // media
  smallMobileSize: '374px',
  mobileSize: '767px',
  tabletSize: '1024px',

  // jivo site
  jivoMobile: `url(${images.jivoMobile.path}) no-repeat 50% 50% !important`,

  // circle progress bar
  progressCircleDesktopSize: 170,
  progressCircleMobileSize: 140,
  progressCircleTrailColor: '#E2E8F0',

  // variables to disable modules
  isHidePartners: true,
  isHideBonuses: true,
  isHideEmailChange: true,
  // exchange only for authorized users
  requiredAuthForExchange: true,

  images,
  paymentImages,
};

export const themeDesignConfig: ThemeDesignConfig = {
  colors: {
    button: {
      fill: {
        primaryBg: '#F59E0B',
        primaryHover: '#FBBF24',
        primaryPressed: '#D97706',
        disabled: '#CBD5E1',
        secondaryBg: '#E2E8F0',
        secondaryHover: '#CBD5E1',
        secondaryPressed: '#94A3B8',
        dangerBg: '#F87171',
        dangerHover: '#FCA5A5',
        dangerPressed: '#EF4444',
        tertiary01: '#FFFFFF',
        tertiaryHover01: '#FFF7ED',
        tertiaryPressed01: '#FFEDD5',
        tertiary02: '#000000',
        tertiaryHover02: '#60A5FA20',
        tertiaryPressed02: '#60A5FA40',
        tertiary03: '#000000',
        tertiaryHover03: '#F8717120',
        tertiaryPressed03: '#F8717140',
        gradient: 'linear-gradient(180deg, #FFB13D 0%, #F97316 100%)',
      },
      text: {
        primaryLabel: '#0F172A',
        secondaryLabel: '#0F172A',
        tertiaryLabel01: '#F97316',
        tertiaryLabel02: '#2563EB',
        tertiaryLabel03: '#EF4444',
        linkLabel: '#000000',
        linkLabelHover: '#334155',
        linkLabelPressed: '#1E293B',
        textButtonDisable: '#94A3B8',
      },
      border: {
        secondary: '#FFFFFF',
        border01: '#F97316',
        border02: '#3B82F6',
        border03: '#DC2626',
        borderDisable: '#94A3B8',
      },
      icon: {
        icon01: '#F97316',
        iconHover01: '#FB923C',
        iconPressed01: '#EA580C',
        icon02: '#0F172A',
        iconHover02: '#334155',
        iconPressed02: '#475569',
        iconDisable: '#CBD5E1',
      },
    },
    background: {
      mainPage: '#D5E0ED',
      overlay: '#1B1C2180', // 80% opacity
      foreground: '#FFFFFF',
      loader: 'rgba(197, 197, 197, 0.4)',
      modal: '#FFFFFF',
      header: 'rgba(255, 255, 255, 0.00)',
      brand: '#F97316',
      success: '#16A34A',
      warning: '#CA8A04',
      danger: '#EF4444',
      info: '#2563EB',
      inverse: '#0F172A',
    },
    layer: {
      layer01: '#F8FAFC',
      layerHover01: '#E2E8F0',
      layerSelected01: '#E5E7EB',
      layerBrand: 'rgba(249, 115, 22, 0.2)',
      layer02: '#F1F5F9',
      layer03: 'rgba(107, 114, 128, 0.12)',
      layer04: 'rgba(107, 114, 128, 0.12)',
      success: 'rgba(74, 222, 128, 0.2)',
      warning: 'rgba(250, 204, 21, 0.2)',
      danger: 'rgba(239, 68, 68, 0.1)',
      info: 'rgba(59, 130, 246, 0.1)',
    },
    text: {
      primary: '#374151',
      secondary: '#4B5563',
      subtle: '#6B7280',
      placeholder: '#9CA3AF',
      inverse: '#F9FAFB',
      disabledSubtle: '#CBD5E1',
      disabledStrong: '#94A3B8',
      brand: '#F97316',
      success: '#16A34A',
      warning: '#CA8A04',
      danger: '#EF4444',
      info: '#3B82F6',
    },
    icons: {
      primary: '#374151',
      secondary: '#4B5563',
      subtle: '#6B7280',
      disabledStrong: '#94A3B8',
      inverse: '#F9FAFB',
      success: '#16A34A',
      warning: '#CA8A04',
      danger: '#EF4444',
      info: '#3B82F6',
    },
    border: {
      system: 'rgba(255, 255, 255, 0)',
      tile: 'rgba(255, 255, 255, 0)',
      separator: '#E2E8F0',
      field: 'rgba(255, 255, 255, 0)',
      interactive: '#94A3B8',
      hover: '#64748B',
      focus: '#F97316',
      success: '#4ADE80',
      warning: '#EAB308',
      danger: '#EF4444',
      info: '#60A5FA',
    },
    field: {
      default: 'rgba(100,116,139,0.08)',
      hover: 'rgba(100,116,139, 0.12)',
      focused: 'rgba(100,116,139, 0.08)',
      disabled: '#E5E7EB',
    },
    bonuses: {
      bronze01: '#D97706',
      bronze02: 'rgba(217, 119, 6, 0.2)',
      silver01: '#64748B',
      silver02: 'rgba(100, 116, 139, 0.2)',
      gold01: '#CA8A04',
      gold02: 'rgba(161, 98, 7, 0.2)', // updated with 20% opacity
      platinum01: '#3B82F6',
      platinum02: 'rgba(59, 130, 246, 0.2)',
      diamond01: '#06B6D4',
      diamond02: 'rgba(6, 182, 212, 0.2)',
    },
    overlay: {
      '01': 'rgba(24,24,27,0.1)',
      '02': 'rgba(24,24,27,0.2)',
      '03': 'rgba(24,24,27,0.3)',
      '04': 'rgba(24,24,27,0.4)',
      '05': 'rgba(24,24,27,0.5)',
      '06': 'rgba(24,24,27,0.6)',
      '07': 'rgba(24,24,27,0.7)',
      '08': 'rgba(24,24,27,0.8)',
      '09': 'rgba(24,24,27,0.9)',
    },
  },

  spacing: {
    's-none': '0px',
    's-01': '1px',
    's-02': '2px',
    's-04': '4px',
    's-06': '6px',
    's-08': '8px',
    's-10': '10px',
    's-12': '12px',
    's-16': '16px',
    's-20': '20px',
    's-24': '24px',
    's-28': '28px',
    's-32': '32px',
    's-36': '36px',
    's-40': '40px',
    's-44': '44px',
    's-48': '48px',
    's-56': '56px',
    's-64': '64px',
    's-80': '80px',
    'btn-content': '8px',
    'btn-y': '16px',
    'btn-x': '24px',
    'form-y': '40px',
    'form-x': '28px',
    'frame-y': '36px',
    'frame-x': '36px',
    'modal-y': '40px',
    'modal-x': '36px',
  },

  radius: {
    mainStrong: '16px',
    mainSubtle: '8px',
    frame: '24px',
    modal: '24px',
    button: '8px',
    field: '12px',
    layer: '8px',
    card: '10px',
  },

  typography: {
    fontFamily: 'Nunito Sans',
    fontFamilyCyrillic: 'Nunito Sans Cyrillic',
    mainFont: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    'display-large': {
      size: '40px',
      height: '54px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'display-medium': {
      size: '32px',
      height: '38px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'heading-large': {
      size: '28px',
      height: '32px',
      weight: 500,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'heading-medium': {
      size: '22px',
      height: '30px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'heading-small': {
      size: '18px',
      height: '24px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'label-large': {
      size: '16px',
      height: '22px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'label-medium': {
      size: '14px',
      height: '20px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'label-small': {
      size: '12px',
      height: '16px',
      weight: 600,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'paragraph-large': {
      size: '16px',
      height: '22px',
      weight: 600,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'paragraph-medium': {
      size: '14px',
      height: '20px',
      weight: 400,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'paragraph-small': {
      size: '12px',
      height: '14px',
      weight: 400,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'overline-medium': {
      size: '12px',
      height: '28px',
      weight: 600,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'overline-small': {
      size: '11px',
      height: '14px',
      weight: 700,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
    'overline-x-small': {
      size: '10px',
      height: '12px',
      weight: 300,
      family: 'Nunito Sans, Nunito Sans Cyrillic, sans-serif',
    },
  },
  illustrations: {
    referral: images.referralIllustration,
    phoneProfile: images.phoneProfileIllustration,
    api: images.apiIllustration,
    emptyTable: images.emptyTableIllustration,
  },
  background: {
    main: `url(${images.mainBg.path}) no-repeat 50% / cover`,
    mobile: `url(${images.mainBgMobile.path}) repeat top / cover 100%, #16161B`,
  },
};

export default {
  ...themeConfig,
  ...themeDesignConfig,
} as Theme;
