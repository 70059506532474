import * as React from 'react';
import { SVGProps } from 'react';

const SvgSecurityIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 8h-3V5.75a4.5 4.5 0 1 0-9 0V8h-3A1.5 1.5 0 0 0 3 9.5V20a1.5 1.5 0 0 0 1.5 1.5h15A1.5 1.5 0 0 0 21 20V9.5A1.5 1.5 0 0 0 19.5 8Zm-15 4.5h15V14h-15v-1.5Zm0 3h15V17h-15v-1.5ZM9 5.75a3 3 0 1 1 6 0V8H9V5.75ZM19.5 9.5V11h-15V9.5h15Zm0 10.5h-15v-1.5h15V20Z"
        fill="#475569"
      />
    </svg>
  );
};
export default SvgSecurityIcon;
