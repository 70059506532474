import * as React from 'react';
import { SVGProps } from 'react';

const SvgCryptoCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 3.75h-15A1.5 1.5 0 0 0 3 5.25v5.51c0 8.4 7.108 11.188 8.531 11.661.304.104.634.104.938 0C13.894 21.948 21 19.161 21 10.76V5.25a1.5 1.5 0 0 0-1.5-1.5ZM12 21c-.868-.289-4.058-1.53-5.988-4.64L12 12.164l5.988 4.192C16.06 19.463 12.876 20.705 12 21Zm7.5-10.236c0 1.654-.315 3.06-.817 4.251l-6.253-4.376a.75.75 0 0 0-.86 0l-6.253 4.373c-.502-1.191-.817-2.597-.817-4.25V5.25h15v5.514Z"
        fill="#64748B"
      />
    </svg>
  );
};
export default SvgCryptoCheckIcon;
