import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 12a7.985 7.985 0 0 0 3 6.246v2.416A9.995 9.995 0 0 1 2 12h2Zm8-10c5.185 0 9.449 3.947 9.95 9h-2.012A8.001 8.001 0 0 0 5.385 7.5H8v2H2v-6h2V6a9.98 9.98 0 0 1 8-4Z"
        fill="#374151"
      />
      <g clipPath="url(#a)">
        <path
          d="M11.111 11.651a5.829 5.829 0 0 1 2.067-1.194 2.329 2.329 0 0 0 2.833.646 2.33 2.33 0 0 0 .811-.646 5.829 5.829 0 0 1 2.067 1.194 2.33 2.33 0 0 0 .857 2.776c.29.197.62.327.965.38a5.857 5.857 0 0 1 0 2.385 2.331 2.331 0 0 0-1.822 3.156 5.827 5.827 0 0 1-2.067 1.194 2.329 2.329 0 0 0-2.833-.645 2.327 2.327 0 0 0-.811.646 5.826 5.826 0 0 1-2.067-1.194 2.33 2.33 0 0 0-1.822-3.155 5.859 5.859 0 0 1 0-2.387 2.327 2.327 0 0 0 1.67-1.14 2.328 2.328 0 0 0 .152-2.016Zm4.764 5.864a1.75 1.75 0 1 0-1.73-3.044 1.75 1.75 0 0 0 1.73 3.044Z"
          fill="#374151"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(8 9)" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgApiIcon;
