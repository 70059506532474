import { ImageMap } from '../contracts';

import file1, { ReactComponent as JSX1 } from './public/pm/1.svg';
import file2, { ReactComponent as JSX2 } from './public/pm/2.svg';
import file3, { ReactComponent as JSX3 } from './public/pm/3.svg';
import file4, { ReactComponent as JSX4 } from './public/pm/4.svg';
import file5, { ReactComponent as JSX5 } from './public/pm/5.svg';
import file6, { ReactComponent as JSX6 } from './public/pm/6.svg';
import file7, { ReactComponent as JSX7 } from './public/pm/7.svg';
import file8, { ReactComponent as JSX8 } from './public/pm/8.svg';
import file9, { ReactComponent as JSX9 } from './public/pm/9.svg';
import file10, { ReactComponent as JSX10 } from './public/pm/10.svg';
import file11, { ReactComponent as JSX11 } from './public/pm/11.svg';
import file12, { ReactComponent as JSX12 } from './public/pm/12.svg';
import filedefault, {
  ReactComponent as JSXdefault,
} from './public/pm/default.svg';

export const paymentImages: ImageMap = {
  1: { path: file1, component: JSX1 },
  10: { path: file10, component: JSX10 },
  11: { path: file11, component: JSX11 },
  12: { path: file12, component: JSX12 },
  2: { path: file2, component: JSX2 },
  3: { path: file3, component: JSX3 },
  4: { path: file4, component: JSX4 },
  5: { path: file5, component: JSX5 },
  6: { path: file6, component: JSX6 },
  7: { path: file7, component: JSX7 },
  8: { path: file8, component: JSX8 },
  9: { path: file9, component: JSX9 },
  default: { path: filedefault, component: JSXdefault },
};
